/*----------------------------------------*/
/*  04. Page CSS
/*----------------------------------------*/

@import "page/slider";
@import "page/clients-about";
@import "page/why-choose-counter";
@import "page/work";
@import "page/achieved";
@import "page/testimonial";
@import "page/skill";
@import "page/pricing";
@import "page/projects";
@import "page/project-details";
@import "page/blog";
@import "page/blog-details";
@import "page/contact";
@import "page/faq";


